<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import rejectionModal from "../../../common/modals/rejectionModal";
import approvalModal from "../../../common/modals/approvalModal";
import { required } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Réservation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, rejectionModal, approvalModal },
  data() {
    return {
      // title: "Project Overview",
      uuid: "",
      items: [
        {
          text: "Hébergement",
          href: "/",
        },
        {
          text: "Réservation",
          active: true,
        },
      ],
      showCheckInModal: false,
      showCheckOutModal: false,
      dtpConfig: {
          format: "YYYY-MM-DD h:mm:ss",
          useCurrent: false,
          showClear: true,
          showClose: true,
          icons: {
            time: "far fa-clock",
            date: "far fa-calendar",
            up: "fas fa-arrow-up",
            down: "fas fa-arrow-down",
            previous: "fas fa-chevron-left",
            next: "fas fa-chevron-right",
            today: "fas fa-calendar-check",
            clear: "far fa-trash-alt",
            close: "far fa-times-circle",
          },
        },
      checkInForm: {
        checkInDate: "",
        bookingUid: "",
        comments: "",
      },

      checkOutForm: {
        checkOutDate: "",
        bookingUid: "",
        comments: "",
      },
      bookingData: {
        id: 0,
        uuid: "",
        ref: "",
        occupant: "",
        occupantFullName: "",
        planned_arrival_date: "",
        planned_departure_date: "",
        real_arrival_date: "",
        real_departure_date: "",
        nights: "",
        checkin_at: "",
        checkout_at: "",
        checkedInBy: "",
        checkInAt: "",
        checkOutAt: "",
        checkInComments: "",
        checkOutComments: "",
        checkedOutBy: "",
        supportRequest: "",
        comments: "",
        statusPlain: "",
        status: "",
        createdBy: "",
        createdAt: "",
        modelType: "",
        approvedBy: "",
        approvedAt: "",
        room: {},
        checkin: false,
        checkout: false,
        rejectedBy: "",
        rejectionDate: "",
        rejectionReason: "",
      },
      submitted: false,
    };
  },

  validations: {
    checkInForm: {
      checkInDate: { required },
    },

    checkOutForm: {
      checkOutDate: { required },
    },
  },

  mounted() {
    this.fetchSinglebookingData();
  },

  methods: {
    processCheckIn() {
      this.submitted = true;
      this.$v.checkInForm.$touch();
      if (!this.$v.checkInForm.$invalid) {
        this.$http
          .post("/ops/booking/checkin", this.checkInForm)
          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.fetchSinglebookingData();
                this.showCheckInModal = false;
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
    processCheckOut() {
      this.submitted = true;
      this.$v.checkOutForm.$touch();
      if (!this.$v.checkOutForm.$invalid) {
        this.$http
          .post("/ops/booking/checkout", this.checkOutForm)
          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.fetchSinglebookingData();
                this.showCheckOutModal = false;
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
    fetchSinglebookingData() {
      this.$http
        .post("/ops/booking/getSingleBookingData/" + this.$route.params.uid)

        .then((res) => {
          var status = res.data.original.status;
          var booking = res.data.original.data.booking;
          switch (status) {
            case 200:
              this.bookingData.id = booking.id;
              this.bookingData.uuid = booking.uuid;
              this.bookingData.ref = booking.ref;
              this.bookingData.checkout = booking.checkout;
              this.bookingData.checkin = booking.checkin;
              this.bookingData.createdBy = booking.createdBy;
              this.bookingData.occupant = booking.occupant;
              this.bookingData.room = booking.room;
              this.bookingData.occupantFullName = booking.occupantFullName;
              this.bookingData.planned_arrival_date = booking.planned_arrival_date;
              this.bookingData.planned_departure_date = booking.planned_departure_date;
              this.bookingData.real_arrival_date = booking.real_arrival_date;
              this.bookingData.real_departure_date = booking.real_departure_date;
              this.bookingData.nights = booking.nights;
              this.bookingData.checkin_at = booking.checkin_at;
              this.bookingData.checkout_at = booking.checkout_at;
              this.bookingData.checkedInBy = booking.checkedInBy;
              this.bookingData.checkInAt = booking.checkInAt;
              this.bookingData.checkOutAt = booking.checkOutAt;
              this.bookingData.checkInComments = booking.checkInComments;
              this.bookingData.checkOutComments = booking.checkOutComments;
              this.bookingData.checkedOutBy = booking.checkedOutBy;
              this.bookingData.supportRequest = booking.supportRequest;
              this.bookingData.comments = booking.comments;
              this.bookingData.statusPlain = booking.statusPlain;
              this.bookingData.status = booking.status;
              this.bookingData.createdAt = booking.createdAt;
              this.bookingData.modelType = booking.modelType;
              this.bookingData.approvedBy = booking.approvedBy;
              this.bookingData.approvedAt = booking.approvedAt;
              this.checkInForm.bookingUid = booking.uuid;
              this.checkOutForm.bookingUid = booking.uuid;
              this.bookingData.rejectedBy = booking.rejectedBy;
              this.bookingData.rejectionDate = booking.rejectionDate;
              this.bookingData.rejectionReason = booking.rejectionReason;
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="bookingData.ref" :items="items" />
    <div class="row" v-if="!bookingData.checkout && bookingData.statusPlain != 'rejected'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="button-items" v-if="!bookingData.checkout">
              <b-button
                variant="primary"
                v-if="bookingData.statusPlain == 'draft' && $can('update_hosting_booking')"
              >
                <i class="bx bx-edit font-size-16 align-middle mr-2"></i>
                Modifier
              </b-button>
              <b-button
                variant="success"
                v-b-modal.approvalModal
                v-if="bookingData.statusPlain == 'draft' && $can('approve_booking')"
              >
                <i
                  class="bx bx-check-double font-size-16 align-middle mr-2"
                ></i>
                Approuver
              </b-button>

              <b-button
                variant="success"
                @click="showCheckInModal = true"
                v-if="bookingData.statusPlain == 'approved' && !bookingData.checkin && $can('checkin_booking')"
              >
                <i
                  class="bx bx-check-square font-size-16 align-middle mr-2"
                ></i>
                Check IN
              </b-button>

              <b-button
                variant="warning"
                @click="showCheckOutModal = true"
                v-if="bookingData.statusPlain == 'approved' && bookingData.checkin && !bookingData.checkout && $can('checkout_booking')"
              >
                <i class="bx bx-exit font-size-16 align-middle mr-2"></i>
                Check OUT
              </b-button>

              <b-button
                variant="danger"
                v-b-modal.rejectionModal
                v-if="bookingData.statusPlain == 'draft' && $can('reject_booking')"
              >
                <i class="bx bx-block font-size-16 align-middle mr-2"></i>
                Rejeter
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
              Détails de réservation N°: <b>{{ bookingData.ref }}</b>
            </h4>
            <a @click="fetchSinglebookingData()" href="javascript:void()"
              ><h1><i class="bx bx-rotate-right" style="float: right;"></i></h1
            ></a>
            <p class="card-title-desc"></p>
            <b-tabs content-class="p-3 text-muted">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Informations</span>
                </template>
                <div class="row">
                  <div class="col-md-6">
                    <div class="table-responsive mb-0">
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>Occupant:</th>
                            <td>{{ bookingData.occupantFullName }}</td>
                          </tr>
                          <tr>
                            <th>Date d'arrivé:</th>
                            <td>{{ bookingData.planned_arrival_date }}</td>
                          </tr>
                          <tr>
                            <th>Date de départ:</th>
                            <td>
                              {{ bookingData.planned_departure_date }} /
                              {{ bookingData.nights }} Nuitées.
                            </td>
                          </tr>
                          <tr>
                            <th>Réservation créer par:</th>
                            <td>{{ bookingData.createdBy }}</td>
                          </tr>
                          <tr>
                            <th>Date de création:</th>
                            <td>{{ bookingData.createdAt }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="table-responsive mb-0">
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>Chambre :</th>
                            <td>
                              <b>{{ bookingData.room.type }}</b> N°:
                              <b>{{ bookingData.room.number }}</b>
                            </td>
                          </tr>
                          <tr>
                            <th>Etat :</th>
                            <td>
                              <span v-html="bookingData.status"></span>&nbsp;
                              <span v-if="bookingData.checkin"><span class="badge rounded-pill bg-success ms-1">CHECKIN</span></span> &nbsp;
                              <span v-if="bookingData.checkout"><span class="badge rounded-pill bg-warning ms-1">CHECKOUT</span></span>&nbsp;
                              <span></span>
                            </td>
                          </tr>
                          <tr v-if="bookingData.statusPlain == 'rejected'">
                            <th>Rejeté par :</th>
                            <td>{{ bookingData.rejectedBy }}</td>
                          </tr>
                          <tr v-if="bookingData.statusPlain == 'rejected'">
                            <th>Date de rejet:</th>
                            <td>{{ bookingData.rejectionDate }}</td>
                          </tr>
                          <tr v-if="bookingData.statusPlain == 'rejected'">
                            <th>Raison de rejet:</th>
                            <td>{{ bookingData.rejectionReason }}</td>
                          </tr>

                          <tr v-if="bookingData.statusPlain == 'approved'">
                            <th>Approuvée par :</th>
                            <td>{{ bookingData.approvedBy }}</td>
                          </tr>

                          <tr v-if="bookingData.statusPlain == 'approved'">
                            <th>Date d'approbation :</th>
                            <td>{{ bookingData.approvedAt }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab v-if="bookingData.checkin">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">CHECK-IN</span>
                </template>
                <div class="row">
                  <div class="col-md-6">
                    <div class="table-responsive mb-0">
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>Date:</th>
                            <td>{{ bookingData.checkin_at }}</td>
                          </tr>
                          <tr>
                            <th>Opération par::</th>
                            <td>{{ bookingData.checkedInBy }}</td>
                          </tr>
                          <tr>
                            <th>Commentaires:</th>
                            <td>
                              {{ bookingData.checkInComments }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab v-if="bookingData.checkout">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">CHECK-OUT</span>
                </template>
                <div class="row">
                  <div class="col-md-6">
                    <div class="table-responsive mb-0">
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>Date:</th>
                            <td>{{ bookingData.checkout_at }}</td>
                          </tr>
                          <tr>
                            <th>Opération par::</th>
                            <td>{{ bookingData.checkedOutBy }}</td>
                          </tr>
                          <tr>
                            <th>Commentaires:</th>
                            <td>
                              {{ bookingData.checkOutComments }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- CHECKIN MODAL -->
    <Modal v-model="showCheckInModal" title="CHECK IN">
      <form @submit.prevent="processCheckIn">
        <b-alert show variant="info">
          <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
          obligatoires sont marqués avec (*)
        </b-alert>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Date / Heure *</label>
              <date-picker
                v-model="checkInForm.checkInDate"
                :class="{
                  'is-invalid': submitted && $v.checkInForm.checkInDate.$error,
                }"
                :config="dtpConfig"
              ></date-picker>
              <div
                v-if="submitted && $v.checkInForm.checkInDate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.checkInForm.checkInDate.required"
                  >La date est obligatoire.</span
                >
                <br />
              </div>
            </div>
          </div>
          <!--  -->
          <div class="col-md-12">
            <div class="form-group">
              <label>Commentaires</label>
              <textarea
                class="form-control"
                rows="5"
                v-model="checkInForm.comments"
              ></textarea>
            </div>
          </div>
        </div>

        <div>
          <button class="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    </Modal>

    <!-- CHECKOUT MODAL -->
    <Modal v-model="showCheckOutModal" title="CHECK OUT">
      <form @submit.prevent="processCheckOut">
        <b-alert show variant="info">
          <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
          obligatoires sont marqués avec (*)
        </b-alert>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Date / Heure *</label>
              <date-picker
                v-model="checkOutForm.checkOutDate"
                :class="{
                  'is-invalid': submitted && $v.checkOutForm.checkOutDate.$error,
                }"
                :config="dtpConfig"
              ></date-picker>
              <div
                v-if="submitted && $v.checkOutForm.checkOutDate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.checkOutForm.checkOutDate.required"
                  >La date est obligatoire.</span
                >
                <br />
              </div>
            </div>
          </div>
          <!--  -->
          <div class="col-md-12">
            <div class="form-group">
              <label>Commentaires</label>
              <textarea
                class="form-control"
                rows="5"
                v-model="checkInForm.comments"
              ></textarea>
            </div>
          </div>
        </div>

        <div>
          <button class="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    </Modal>

    <approval-modal
      :modelRef="bookingData.ref"
      :modelId="bookingData.id"
      :modelType="bookingData.modelType"
    />
    <rejection-modal
      :modelRef="bookingData.ref"
      :modelId="bookingData.id"
      :modelType="bookingData.modelType"
    />
  </Layout>
</template>
